//@author: mandy
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { S25Const } from "../util/s25-const";
import { ListGeneratorService } from "./list.generator.service";
import { FlsService } from "./fls.service";
import { PreferenceService } from "./preference.service";
import { jSith } from "../util/jquery-replacement";
import { Timeout } from "../decorators/timeout.decorator";
import { LooseAutocomplete } from "../pojo/Util";
import { Proto } from "../pojo/Proto";
import { Item } from "../pojo/Item";
import NumericalString = Proto.NumericalString;

export class PublisherService {
    public static PublisherMap: any = {
        location: {
            query_id: 999,
            name: 1,
            category_id: 2,
            feature_id: 3,
            layout_id: 4,
            min_capacity: 5,
            max_capacity: 6,
        },

        event: {
            query_id: 999,
            name: 1,
            cabinet_id: 2,
            event_type_id: 3,
            organization_id: 4,
            category_id: 5,
            state: 6,
            role_id: 7,
        },
    };

    public static cookiePath: any = null;

    public static publisherBean: Publisher.Bean = {
        cachedUsername: undefined,
        cachedPassword: undefined,
        cachedSearchQuery: undefined,
        cachedSearchQueryHash: undefined,
        calendarTree: undefined,
        calendarList: undefined,
    };

    public static transformCalendar(calendar: Publisher.ProxyPublisherCalendar[], depth: number) {
        depth = depth || 0;
        if (depth === 0) {
            PublisherService.publisherBean.calendarList = [];
        }

        let respCalendar: Publisher.ProxyBuilderTransformedCalendar[] = [];
        calendar = S25Util.array.forceArray(calendar);
        let calLen = calendar.length;
        for (let i = 0; i < calLen; i++) {
            let obj: Publisher.ProxyBuilderTransformedCalendar = {
                id: calendar[i].ID,
                name: calendar[i].Name,
            };
            PublisherService.publisherBean.calendarList.push({
                id: obj.id,
                name: obj.name,
                dispName: "--".repeat(depth) + obj.name,
            });
            if (calendar[i].Calendar) {
                obj.calendar = PublisherService.transformCalendar(calendar[i].Calendar, depth + 1);
            }
            respCalendar.push(obj);
        }
        return respCalendar;
    }

    //public static  getPubisherList : any = function() {
    public static getPublisherList: any = (function () {
        let colsArray: any[] = [
            //note: prefId used to identify col w/o using prefname, which will make col avail in Choose Columns and pref PUT, etc
            //so we use prefId so that the col will NOT be hide-able through Choose Columns and will NOT get saved in any prefs, just always visible...
            //note that if a col has prefname, it is auto included in Choose Columns and pref updates, etc
            { name: "Update Now?", templateType: 2, prefId: "update" },
            { name: "Object/Group", sortable: 1, prefname: "object_name", isPermanent: 1, isDefaultVisible: 1 },
            { name: "Status", sortable: 1, prefname: "status", isPermanent: 1, templateType: 4, isDefaultVisible: 1 },
            { name: "Status Message", prefname: "status_msg", isDefaultVisible: 0 },
            { name: "25Live User", sortable: 1, prefname: "r25_user", isDefaultVisible: 0 },
            { name: "Type", sortable: 1, prefname: "object_type", isDefaultVisible: 0 },
            { name: "Query", sortable: 1, prefname: "r25ws_query", isDefaultVisible: 0 },
            { name: "Calendar", sortable: 1, prefname: "calendar_name", isDefaultVisible: 1 },
            { name: "Calendar ID", sortable: 1, prefname: "calendar_id", isDefaultVisible: 0 },
            { name: "Creation Date", sortable: 1, isDateTime: 1, prefname: "send_date", isDefaultVisible: 0 },
            { name: "Feed UID", sortable: 1, prefname: "feed_uid", isDefaultVisible: 0 },
            { name: "Delete?", templateType: 3, prefId: "delete" },
        ];

        let rowsF = function (listData: any) {
            let ret: any[] = [];
            if (listData && listData.publisher && listData.publisher.history && listData.publisher.history.feed) {
                jSith.forEach(listData.publisher.history.feed, function (i, feed) {
                    //set data rows
                    ret.push({
                        row: [
                            { templateType: 2, uid: feed.uid, feed_id: feed.dataset.data_id }, //update / sync feed btn: this cell is a template type, see list tmpls for the markup this will use vis-a-vis s25ListTemplateCell
                            feed.dataset.data_name,
                            { templateType: 4, status: feed.status },
                            feed.status_msg,
                            feed.r25ws.user,
                            feed.dataset.data_type === "space" ? "location" : feed.dataset.data_type,
                            feed.dataset.data_query,
                            feed.calendar.cal_name,
                            feed.calendar.cal_id,
                            feed.pubdate,
                            feed.uid,
                            {
                                templateType: 3,
                                uid: feed.uid,
                                feed_id: feed.dataset.data_id,
                                feedName: feed.dataset.data_name,
                                calName: feed.calendar.cal_name,
                            }, //delete feed btn
                        ],
                    });
                });
            }
            return ret;
        };
        return ListGeneratorService.s25Generate("25L_publisher_admin_columns", colsArray, rowsF, function () {
            return PublisherService.getPublisherListDao.apply(this, [].slice.call(arguments));
        });
    })();

    public static getCalendarTree() {
        if (
            PublisherService.publisherBean.calendarTree &&
            PublisherService.publisherBean.calendarTree.Response &&
            PublisherService.publisherBean.calendarTree.Response.Calendar
        ) {
            return PublisherService.transformCalendar(
                PublisherService.publisherBean.calendarTree.Response.Calendar,
                null,
            );
        }
    }

    public static getCalendarList(noCache?: boolean) {
        //only ever ran after logged in...
        if (noCache) {
            return PublisherService.getCalendars().then(function () {
                PublisherService.getCalendarTree();
                return PublisherService.publisherBean.calendarList;
            });
        } else {
            PublisherService.getCalendarTree();
            return PublisherService.publisherBean.calendarList;
        }
    }

    @Timeout
    public static getCalendars() {
        //only ever called after logged in...
        return PublisherService.getCalendarsDao(
            PublisherService.publisherBean.cachedUsername,
            PublisherService.publisherBean.cachedPassword,
        ).then(function (data) {
            PublisherService.publisherBean.calendarTree = data;
        });
    }

    public static getHistoryList(data: Publisher.RawHistory) {
        let respList: Publisher.SimpleHistoryItem[] = [];
        if (data && data.publisher && data.publisher.history) {
            for (let i = 0; i < data.publisher.history.length; i++) {
                let feeds = data.publisher.history[i].feed;
                for (let j = 0; j < feeds.length; j++) {
                    if (feeds[j] && feeds[j].calendar) {
                        respList.push({
                            id: feeds[j].calendar.cal_id,
                            name: feeds[j].calendar.cal_name,
                            uid: feeds[j].uid,
                        });
                    }
                }
            }
            return respList;
        }
    }

    // TODO(use JSON instead of XMl once ACCL-967 done)
    @Timeout
    public static _getHistory(compsubject: any, searchQueryHash: any, feedType: any, itemId: any) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/publisher/history.xml" +
                    "?username=" +
                    encodeURIComponent(PublisherService.publisherBean.cachedUsername) +
                    (feedType === "r25_group" ? "&group_id=" + searchQueryHash : "&obj_id=" + itemId) +
                    "&type_name=" +
                    (compsubject === "event" ? "event" : "space"),
                "PublisherService.getHistory",
            ),
        ).then(function (data) {
            //return data;
            return data && (S25Util.prettyConv(data, null, { history: true, feed: true }) as Publisher.RawHistory);
        });
    }

    public static getHistory(compsubject: any, searchQuery: any, feedType: any, itemId: any) {
        return PublisherService.getDataQuery(feedType, itemId, compsubject, searchQuery).then(function (searchQuery) {
            PublisherService.publisherBean.cachedSearchQueryHash = window.md5(searchQuery);
            return PublisherService._getHistory(
                compsubject,
                PublisherService.publisherBean.cachedSearchQueryHash,
                feedType,
                itemId,
            );
        });
    }

    public static getUsername() {
        return PublisherService.publisherBean.cachedUsername;
    }

    @Timeout
    public static login(username?: any, password?: any) {
        username = PublisherService.publisherBean.cachedUsername || username;
        password = PublisherService.publisherBean.cachedPassword || password;

        if (S25Util.isUndefined(username) && S25Util.isUndefined(password)) {
            username = S25Util.getCookie("publisherUsername");
            password = S25Util.getCookie("publisherPassword");
        }

        if (S25Util.isUndefined(username) || S25Util.isUndefined(password)) {
            return jSith.when(false);
        } else {
            return PublisherService.loginDao(username, password).then(
                function (isLoggedIn: any) {
                    if (isLoggedIn) {
                        PublisherService.publisherBean.cachedUsername = username;
                        PublisherService.publisherBean.cachedPassword = password;
                        S25Util.setCookie("publisherUsername", username, null, null, PublisherService.cookiePath); //nonExistingPath so cookie is not send with web requests
                        //Note: sendToCalendar actually needs password in the request body...(will remove this and use a more secure token when publisher is migrated)
                        S25Util.setCookie("publisherPassword", password, null, null, PublisherService.cookiePath);
                        return PublisherService.getCalendars().then(function () {
                            return true;
                        });
                    } else {
                        return false;
                    }
                },
                function () {
                    return false;
                },
            );
        }
    }

    public static logout() {
        PublisherService.publisherBean.cachedUsername = undefined;
        PublisherService.publisherBean.cachedPassword = undefined;
        PublisherService.publisherBean.calendarTree = undefined;
        PublisherService.publisherBean.calendarList = undefined;
        PublisherService.publisherBean.cachedSearchQuery = undefined;
        PublisherService.publisherBean.cachedSearchQueryHash = undefined;
        S25Util.deleteCookie("publisherUsername", PublisherService.cookiePath);
        S25Util.deleteCookie("publisherPassword", PublisherService.cookiePath);
    }

    public static searchQueryLocationCompare(a: any, b: any) {
        let aVal = a.slice(0, (a.indexOf("=") > -1 && a.indexOf("=")) || a.length);
        let bVal = b.slice(0, (b.indexOf("=") > -1 && b.indexOf("=")) || b.length);
        aVal = PublisherService.PublisherMap.location[aVal] || 0;
        bVal = PublisherService.PublisherMap.location[bVal] || 0;

        if (aVal < bVal) {
            return -1;
        } else if (aVal === bVal) {
            return 0;
        } else {
            return 1;
        }
    }

    public static searchQueryEventCompare(a: any, b: any) {
        let aVal = a.slice(0, (a.indexOf("=") > -1 && a.indexOf("=")) || a.length);
        let bVal = b.slice(0, (b.indexOf("=") > -1 && b.indexOf("=")) || b.length);
        aVal = PublisherService.PublisherMap.event[aVal] || 0;
        bVal = PublisherService.PublisherMap.event[bVal] || 0;

        if (aVal < bVal) {
            return -1;
        } else if (aVal === bVal) {
            return 0;
        } else {
            return 1;
        }
    }

    public static sortDataQuery(searchQuery: any, compsubject: any) {
        if (searchQuery && searchQuery.length > 0) {
            let sortFunction =
                compsubject === "event"
                    ? PublisherService.searchQueryEventCompare
                    : PublisherService.searchQueryLocationCompare;
            return searchQuery.split("&").sort(sortFunction).join("&");
        } else {
            return searchQuery;
        }
    }

    public static getDataQuery(feedType: any, itemId: any, compsubject: any, searchQuery: any): PromiseLike<string> {
        //sets searchQuery used in other routines by setting cachedSearchQuery for them
        let prefixPos = S25Const.webservicesBaseUrl.indexOf("/25live/data/");
        let prefix = S25Const.webservicesBaseUrl.substring(prefixPos);
        if (prefix.slice(-1) !== "/") {
            prefix += "/";
        }

        let isPredefined = searchQuery && ("" + searchQuery).indexOf("query_id") > -1;
        let ret = "";
        let mlFn: any = "";

        if (feedType === "r25_object") {
            //from s25-space_details.xml and s25-event_details.xml
            ret =
                compsubject === "event"
                    ? "reservations.ics?event_id="
                    : compsubject === "location"
                      ? "rm_reservations.ics?space_id="
                      : "";
            if (ret) {
                ret += itemId;
            }
            PublisherService.publisherBean.cachedSearchQuery = ret;
            return jSith.when(ret);
        } else {
            //r25_group...
            if (compsubject === "event") {
                ret += "events.json?";
                mlFn = "getMLEvent";
            } else if (compsubject === "location") {
                ret += "spaces.json?";
                mlFn = "getMLSpace";
            }
            if (!ret) {
                return jSith.when(ret);
            } //not a valid data query, return empty promise
            return (<any>FlsService)[mlFn]().then(function (fls: any) {
                fls = fls || "N";
                ret += compsubject === "event" ? "node_type=E&" : "";
                ret += "scope=extended&";
                ret += "ML_FLS=" + fls + "&";
                ret +=
                    compsubject === "event" && isPredefined
                        ? "include=text+categories+customers+attributes+reservations+spaces&scope=extended&"
                        : "";
                ret += compsubject === "location" && isPredefined ? "include=features+categories+layouts&" : "";
                ret = ret.slice(0, -1);

                searchQuery = searchQuery && searchQuery.replace(/&spaces_/g, "&").replace(/&events_/g, "&");
                searchQuery = (searchQuery && searchQuery[0] === "&" && searchQuery.slice(1)) || searchQuery;
                if (searchQuery) {
                    ret += "&" + PublisherService.sortDataQuery(searchQuery, compsubject);
                }
                PublisherService.publisherBean.cachedSearchQuery = ret; //cachedSearchQuery does not need prefix (other routines using the cached search query do not need it)
                ret = prefix + ret; //but response does (bc this resp is hashed into an identifier and previous hashes used it)
                return ret;
            });
        }
    }

    public static sendToCalendar(modelBean: any) {
        if (modelBean.createOrAdd === "add") {
            return PublisherService.sendToCalendarDao(
                modelBean.addToCalendar.id,
                modelBean.addToCalendar.name,
                modelBean.feedType,
                modelBean.compsubject,
                PublisherService.publisherBean.cachedUsername,
                PublisherService.publisherBean.cachedPassword,
                modelBean.useEventTitle,
                modelBean.spaceFormalName,
                modelBean.reservationComments,
                modelBean.setupTakedown,
                modelBean.rsrvService,
                modelBean.chunkSize,
                modelBean.pastWindow,
                modelBean.futureWindow,
                modelBean.feedType === "r25_group"
                    ? PublisherService.publisherBean.cachedSearchQueryHash
                    : modelBean.itemId,
                modelBean.itemName,
                PublisherService.publisherBean.cachedSearchQuery,
                modelBean.cancelled,
            );
        }
    }

    public static createCalendar(modelBean: any) {
        if (modelBean.createOrAdd === "create") {
            return PublisherService.createCalendarDao(
                (modelBean.isSubCal && modelBean.createAsSubCalendar.id) || undefined,
                modelBean.newCalendarName,
                modelBean.feedType,
                modelBean.compsubject,
                PublisherService.publisherBean.cachedUsername,
                PublisherService.publisherBean.cachedPassword,
                modelBean.useEventTitle,
                modelBean.spaceFormalName,
                modelBean.reservationComments,
                modelBean.setupTakedown,
                modelBean.rsrvService,
                modelBean.chunkSize,
                modelBean.pastWindow,
                modelBean.futureWindow,
                modelBean.feedType === "r25_group"
                    ? PublisherService.publisherBean.cachedSearchQueryHash
                    : modelBean.itemId,
                modelBean.itemName,
                PublisherService.publisherBean.cachedSearchQuery,
                modelBean.cancelled,
            );
        }
    }

    public static removeFeed(id: string) {
        return DataAccess.delete(
            DataAccess.injectCaller("/publisher/delete.json?feed_uid=" + id, "PublisherService.removeFeed"),
        );
    }

    public static updateFeed(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/publisher/synchronize.json?feed_uid=" + id, "PublisherService.updateFeed"),
        );
    }

    public static getPublisherPreferences(modelBean: any) {
        return PreferenceService.getPreferences([
            "config_pub_display_options",
            "config_pub_event_chunk_size",
            "config_pub_event_window_future",
            "config_pub_event_window_past",
            "config_pub_space_chunk_size",
            "config_pub_space_window_future",
            "config_pub_space_window_past",
        ]).then(function (data: any) {
            if (modelBean.compsubject === "event") {
                modelBean.chunkSize = data.config_pub_event_chunk_size && data.config_pub_event_chunk_size.value;
                modelBean.pastWindow = data.config_pub_event_window_past && data.config_pub_event_window_past.value;
                modelBean.futureWindow =
                    data.config_pub_event_window_future && data.config_pub_event_window_future.value;
            } else if (modelBean.compsubject === "location") {
                modelBean.chunkSize = data.config_pub_space_chunk_size && data.config_pub_space_chunk_size.value;
                modelBean.pastWindow = data.config_pub_space_window_past && data.config_pub_space_window_past.value;
                modelBean.futureWindow =
                    data.config_pub_space_window_future && data.config_pub_space_window_future.value;
            }

            if (
                data.config_pub_display_options &&
                data.config_pub_display_options.value &&
                data.config_pub_display_options.value.indexOf
            ) {
                if (data.config_pub_display_options.value.indexOf("use_event_title") > -1) {
                    modelBean.useEventTitle = true;
                }
                if (data.config_pub_display_options.value.indexOf("space_formal_name") > -1) {
                    modelBean.spaceFormalName = true;
                }
                if (data.config_pub_display_options.value.indexOf("reservation_comments") > -1) {
                    modelBean.reservationComments = true;
                }
                if (data.config_pub_display_options.value.indexOf("show_rsrv_comments") > -1) {
                    modelBean.reservationComments = true;
                }
                if (data.config_pub_display_options.value.indexOf("rsrv_service") > -1) {
                    modelBean.rsrvService = true;
                }
                if (data.config_pub_display_options.value.indexOf("setup_takedown") > -1) {
                    modelBean.setupTakedown = true;
                }
            }
        });
    }

    public static async getPublisherPreferencesPure(itemType: Item.Id) {
        const data = await PreferenceService.getPreferences([
            "config_pub_display_options",
            "config_pub_event_chunk_size",
            "config_pub_event_window_future",
            "config_pub_event_window_past",
            "config_pub_space_chunk_size",
            "config_pub_space_window_future",
            "config_pub_space_window_past",
        ]);

        const ret: Publisher.Preferences = {
            chunkSize: undefined,
            pastWindow: undefined,
            futureWindow: undefined,
            useEventTitle: false,
            rsrvService: false,
            spaceFormalName: false,
            reservationComments: false,
            setupTakedown: false,
        };

        if (itemType === Item.Ids.Event) {
            ret.chunkSize = data.config_pub_event_chunk_size?.value;
            ret.pastWindow = data.config_pub_event_window_past?.value;
            ret.futureWindow = data.config_pub_event_window_future?.value;
        } else if (itemType === Item.Ids.Location) {
            ret.chunkSize = data.config_pub_space_chunk_size?.value;
            ret.pastWindow = data.config_pub_space_window_past?.value;
            ret.futureWindow = data.config_pub_space_window_future?.value;
        }

        if (data.config_pub_display_options?.value?.indexOf) {
            if (data.config_pub_display_options.value.includes("use_event_title")) ret.useEventTitle = true;
            if (data.config_pub_display_options.value.includes("space_formal_name")) ret.spaceFormalName = true;
            if (data.config_pub_display_options.value.includes("reservation_comments")) ret.reservationComments = true;
            if (data.config_pub_display_options.value.includes("show_rsrv_comments")) ret.reservationComments = true;
            if (data.config_pub_display_options.value.includes("rsrv_service")) ret.rsrvService = true;
            if (data.config_pub_display_options.value.includes("setup_takedown")) ret.setupTakedown = true;
        }

        return ret;
    }

    ///// FUNCATIONS FROM DAO ////////
    public static formPublisherListParams(params: any) {
        let daoParams: any = "?username=" + params.modelBean.publisherBean.username;
        daoParams +=
            "&sort_key=" +
            ((params.modelBean.sortCol && params.modelBean.sortCol.sort) ||
                params.modelBean.publisherBean.sortKey ||
                "object_name");
        daoParams +=
            "&sort_order=" +
            ((params.modelBean.sortCol && params.modelBean.sortCol.order) ||
                params.modelBean.publisherBean.sortOrder ||
                "asc");
        daoParams += "&search_key=" + (params.modelBean.publisherBean.searchKey || "");
        daoParams +=
            "&paginate" +
            (params.modelBean.cacheId || params.modelBean.publisherBean.paginate
                ? "=" + params.modelBean.cacheId || params.modelBean.publisherBean.paginate
                : "");
        daoParams +=
            "&page=" +
            ((params.modelBean.chosen && params.modelBean.chosen.page) || params.modelBean.publisherBean.page || "");
        daoParams += "&page_size=" + (params.modelBean.itemsPerPage || params.modelBean.publisherBean.page_size || 25);
        return daoParams;
    }

    // TODO(use JSON instead of XMl once ACCL-967 done)
    @Timeout
    public static getPublisherListDao(params: any) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/publisher/history.xml" + PublisherService.formPublisherListParams(params),
                "PublisherService.getPublisherListDao",
            ),
        ).then(function (data: any) {
            //return data;
            return S25Util.prettyConv(data, null, { feed: true });
        });
    }

    @Timeout
    public static proxyPublisher(username: any, password: any, method: any, serviceUrl: any, payload: any) {
        let headers = username && password && { Authorization: "Basic " + window.btoa(username + ":" + password) };
        return DataAccess.post<Publisher.ProxyPublisher>(
            DataAccess.injectCaller(
                "/publisher/api/proxy.json?method=" + method + "&serviceUrl=" + encodeURIComponent(serviceUrl),
                "PublisherService.proxyPublisher",
            ),
            payload,
            headers,
        );
    }

    @Timeout
    public static loginDao(username: any, password: any) {
        return PublisherService.getCalendarsDao(username, password).then(
            function (data: any) {
                return !!(S25Util.propertyGet(data, "Calendar") || S25Util.propertyGet(data, "calendar"));
            },
            function () {
                return false;
            },
        );
    }

    @Timeout
    public static sendToCalendarDao(
        calId: any,
        calName: any,
        feedType: any,
        compsubject: any,
        username: any,
        password: any,
        useEventTitle: any,
        spaceFormalName: any,
        reservationComments: any,
        setupTakedown: any,
        rsrvService: any,
        chunkSize: any,
        pastWindow: any,
        futureWindow: any,
        itemId: any,
        itemName: any,
        searchQuery: any,
        cancelled?: any,
    ) {
        let queryOptions = "&options=trumba+all_properties";
        queryOptions += (useEventTitle && "+event_title") || "";
        queryOptions += (spaceFormalName && "+space_formal_name") || "";
        queryOptions += (reservationComments && "+reservation_comments") || "";
        queryOptions += (setupTakedown && "+task_agenda_events") || "";
        queryOptions += "+all_spaces" || "";
        queryOptions += (cancelled && "+cancelled") || "";
        queryOptions +=
            (feedType === "r25_group" &&
                rsrvService &&
                searchQuery.indexOf("rsrv_service") <= -1 &&
                "&rsrv_service=rm_reservations.ics") ||
            "";

        if (feedType === "r25_object" && compsubject === "event" && rsrvService) {
            //reservations.ics only used for event, rm_reservations.ics already used for location
            searchQuery = searchQuery.replace("reservations.ics", "rm_reservations.ics");
        }

        return DataAccess.get(
            DataAccess.injectCaller("/publisher/template.json", "PublisherService.sendToCalendar"),
        ).then(function (jsonTemplate) {
            if (
                jsonTemplate &&
                jsonTemplate.publisher &&
                jsonTemplate.publisher.feed &&
                jsonTemplate.publisher.feed.calendar &&
                jsonTemplate.publisher.feed.objects &&
                jsonTemplate.publisher.feed.objects.object &&
                jsonTemplate.publisher.feed.groups &&
                jsonTemplate.publisher.feed.groups.group
            ) {
                jsonTemplate.publisher.feed.calendar.cal_id = calId;
                jsonTemplate.publisher.feed.calendar.cal_name = calName;
                jsonTemplate.publisher.feed.calendar.base_url = encodeURIComponent(
                    S25Const.publisher_services as string,
                );
                jsonTemplate.publisher.feed.calendar.user = username;
                jsonTemplate.publisher.feed.calendar.pass = password;

                jsonTemplate.publisher.feed.r25ws.base_url = encodeURIComponent(
                    jsonTemplate.publisher.feed.r25ws.base_url,
                );

                if (feedType === "r25_group") {
                    jsonTemplate.publisher.feed.objects = undefined;
                    jsonTemplate.publisher.feed.groups.group.group_id = itemId;
                    jsonTemplate.publisher.feed.groups.group.group_name = itemName;
                    jsonTemplate.publisher.feed.groups.group.type_name = compsubject === "event" ? "event" : "space";
                    jsonTemplate.publisher.feed.groups.group.query = searchQuery + queryOptions;
                    jsonTemplate.publisher.feed.groups.group.publish.chunksize = chunkSize;
                    jsonTemplate.publisher.feed.groups.group.publish.window.past = pastWindow;
                    jsonTemplate.publisher.feed.groups.group.publish.window.future = futureWindow;
                } else if (feedType === "r25_object") {
                    jsonTemplate.publisher.feed.groups = undefined;
                    jsonTemplate.publisher.feed.objects.object.object_id = itemId;
                    jsonTemplate.publisher.feed.objects.object.object_name = itemName;
                    jsonTemplate.publisher.feed.objects.object.type_name = compsubject === "event" ? "event" : "space";
                    jsonTemplate.publisher.feed.objects.object.query = searchQuery + queryOptions;
                    jsonTemplate.publisher.feed.objects.object.publish.chunksize = chunkSize;
                    jsonTemplate.publisher.feed.objects.object.publish.window.past = pastWindow;
                    jsonTemplate.publisher.feed.objects.object.publish.window.future = futureWindow;
                }

                return DataAccess.put("/publisher/create.xml", S25Util.xml.json2xml_str(jsonTemplate)).then(
                    function (data) {
                        var feedUid = S25Util.propertyGet(S25Util.prettyConv(data), "success");
                        feedUid = (feedUid && feedUid.msg) || "";
                        return DataAccess.get("/publisher/synchronize.json?feed_uid=" + feedUid);
                    },
                );

                // TODO when create.json avaiable
                // return DataAccess.put("/publisher/create.json").then(function(data) {
                //    // var feedUid = S25Util.propertyGet(S25Util.prettyConv(data), "success");
                //    let feedUid = S25Util.propertyGet(data, "success");
                //     feedUid = feedUid && feedUid.msg || "";
                //     return DataAccess.get("/publisher/synchronize.json?feed_uid=" + feedUid);
                // });
            }
        });
    }

    @Timeout
    public static createCalendarDao(
        parentId: any,
        calName: any,
        feedType: any,
        compsubject: any,
        username: any,
        password: any,
        useEventTitle: any,
        spaceFormalName: any,
        reservationComments: any,
        setupTakedown: any,
        rsrvService: any,
        chunkSize: any,
        pastWindow: any,
        futureWindow: any,
        itemId: any,
        itemName: any,
        searchQuery: any,
        cancelled?: any,
    ) {
        let serviceUrl =
            "calendars.asmx/CreateCalendar?Name=" + encodeURIComponent(calName) + "&ParentID=" + (parentId || "");
        return PublisherService.proxyPublisher(username, password, "GET", serviceUrl, null).then(function (data) {
            if (data) {
                if (data && data.Response && data.Response.Calendar && data.Response.Calendar.ID) {
                    return PublisherService.sendToCalendarDao(
                        data.Response.Calendar.ID,
                        calName,
                        feedType,
                        compsubject,
                        username,
                        password,
                        useEventTitle,
                        spaceFormalName,
                        reservationComments,
                        setupTakedown,
                        rsrvService,
                        chunkSize,
                        pastWindow,
                        futureWindow,
                        itemId,
                        itemName,
                        searchQuery,
                        cancelled,
                    );
                } else {
                    return -1; //calendar feed already exists
                }
            }
        });
    }

    @Timeout
    public static getCalendarsDao(username: any, password: any) {
        return PublisherService.proxyPublisher(username, password, "GET", "calendars.asmx/GetCalendarList", null).then(
            function (data) {
                if (data && data.Response && data.Response.Calendar) {
                    data.Response.Calendar = S25Util.array.forceArray(data.Response.Calendar);
                    S25Util.array.inplaceRemoveByProp(
                        data.Response.Calendar,
                        "Name",
                        "Internal Event Actions Calendar",
                    );
                }
                return data;
            },
        );
    }
    ///// END FROM DAO  ///////////////
}

export namespace Publisher {
    export type RawHistory = {
        publisher: {
            engine: LooseAutocomplete<"accl">;
            history?: History[];
        };
    };

    export type History = {
        cache: string;
        group_id: string;
        obj_id: string;
        type_name: LooseAutocomplete<"event">;
        user: string;
        feed: FeedItem[];
    };

    export type FeedItem = {
        uid: string;
        calendar: {
            base_url: string;
            cal_id: NumericalString;
            cal_name: string;
            user: string;
        };
    };

    export type SimpleHistoryItem = {
        id: FeedItem["calendar"]["cal_id"];
        name: FeedItem["calendar"]["cal_name"];
        uid: FeedItem["uid"];
    };

    export type ProxyPublisher = {
        Response: {
            xsd: string;
            Calendar: ProxyPublisherCalendar[] & { ID?: number };
        };
    };

    export type ProxyPublisherCalendar = {
        ID: number;
        Name: string;
        Calendar?: ProxyPublisherCalendar[];
    };

    export type ProxyBuilderTransformedCalendar = {
        id: number;
        name: string;
        calendar?: ProxyBuilderTransformedCalendar[];
    };

    export type CalendarListItem = {
        id: number;
        name: string;
        dispName: string;
    };

    export type Preferences = {
        chunkSize: NumericalString;
        pastWindow: NumericalString;
        futureWindow: NumericalString;
        useEventTitle: boolean;
        rsrvService: boolean;
        spaceFormalName: boolean;
        reservationComments: boolean;
        setupTakedown: boolean;
    };

    export type Bean = {
        cachedUsername: string;
        cachedPassword: string;
        cachedSearchQuery: string;
        cachedSearchQueryHash: string;
        calendarTree: Publisher.ProxyPublisher;
        calendarList: Publisher.CalendarListItem[];
    };

    export type FeedType = "r25_group" | "r25_object";
}
