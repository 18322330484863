//@author: mandy
import { DataAccess } from "../dataaccess/data.access";

export class PersistentSessionService {
    public static create(username: any) {
        return DataAccess.get(
            DataAccess.injectCaller("/session/persistent.json?username=" + username, "PersistentSessionService.get"),
        );
    }

    public static delete(username: any) {
        return DataAccess.delete(
            DataAccess.injectCaller("/session/persistent.json?username=" + username, "PersistentSessionService.delete"),
        );
    }
}
