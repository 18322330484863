import { FlsService } from "./fls.service";
import { Cache } from "../decorators/cache.decorator";
import { UserprefService } from "./userpref.service";
import { ContactService } from "./contact.service";
import { AccessLevels } from "../pojo/Fls";

export class PermissionService {
    @Cache({ targetName: "PermissionService" })
    public static async canCreateBuildings() {
        const [fls, loggedIn, groupId, username] = await Promise.all([
            FlsService.getFls(),
            UserprefService.getLoggedIn(),
            UserprefService.getGroupId(),
            ContactService.getCurrentUsername(),
        ]);
        if (!loggedIn) return false;
        if (username === "service25" || groupId === -1) return true;
        return fls.SPACE_LIST === AccessLevels.Full && fls.ML_SPACE === AccessLevels.Full;
    }
    @Cache({ targetName: "PermissionService" })
    public static async canBulkCreateBuildings() {
        const [fls, loggedIn, groupId, username] = await Promise.all([
            FlsService.getFls(),
            UserprefService.getLoggedIn(),
            UserprefService.getGroupId(),
            ContactService.getCurrentUsername(),
        ]);
        if (!loggedIn) return false;
        if (username === "service25" || groupId === -1) return true;
        return (
            fls.SPACE_LIST === AccessLevels.Full &&
            fls.ML_SPACE === AccessLevels.Full &&
            fls.SPACE_PERM === AccessLevels.Full
        );
    }
}
