//@author travis
import { DataAccess } from "../dataaccess/data.access";
import { PreferenceService } from "./preference.service";
import { Cache } from "../decorators/cache.decorator";
import { S25Util } from "../util/s25-util";

export class OptService {
    public static getS25Opt(comptype: string, compsubject: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/opt/optdata.json?comptype=" +
                    comptype +
                    (compsubject ? "&compsubject=" + compsubject : "") +
                    "&mode=power",
                "OptService.getS25Opt",
            ),
        ).then(function (data) {
            return data;
        });
    }

    public static getOptSearches(comptype: string) {
        return DataAccess.get(
            DataAccess.injectCaller("/opt/optsearches.json?comptype=" + comptype, "OptService.getOptSearches"),
        ).then(function (data) {
            var res = data && data.opt && S25Util.array.forceArray(data.opt);
            return (
                res &&
                res.filter(function (obj: any) {
                    return obj && obj.txt && (obj.txt + "").indexOf("Search_") !== 0;
                })
            );
        });
    }

    //searchContext just used to make calls unique for cache keys
    @Cache({ immutable: false, targetName: "OptService" }) //must be mutable for controllers to store model
    public static getS25OptCached(comptype: string, compsubject: string, searchContext: string) {
        return OptService.getS25Opt(comptype, compsubject);
    }

    public static setQueryPreference(query: string, comptype: string) {
        query.replace("&", ""); //an ampersand here will go straight into the url and break save
        var prefValue;
        var prefType;
        if (comptype === "home" || comptype === "availability_home" || comptype === "homelist") {
            switch (comptype) {
                case "home":
                    prefType = "calendar";
                    break;
                case "availability_home":
                    prefType = "availability";
                    break;
                case "homelist":
                    prefType = "list";
                    break;
            }
            prefValue = "<source>reservations.xml?" + query + "</source>";
            return PreferenceService.setGenericPreference(prefType, "sticky_search", prefValue);
        }
    }
}
