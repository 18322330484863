//@author travis
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { S25Const } from "../util/s25-const";
import { Cache } from "../decorators/cache.decorator";
import { AccessLevel, AccessLevelName } from "../pojo/Fls";

interface OlsResponse {
    object_level_permission: { object_permission: Ols[] };
}
export interface Ols {
    right_name: "EDIT" | "SCHEDULE";
    object_id: number;
    object_type: 1 | 2 | 3 | 4 | 6;
    object_type_name?: "Organization" | "Contact" | "Location" | "Resource";
    access_level: AccessLevel;
    access_level_name?: AccessLevelName;
}

export class OlsService {
    private static Type2OlsService: any = {
        1: "event_ols.json",
        4: "space_ols.json",
        6: "resource_ols.json",
        2: "organization_ols.json",
    };
    @Cache({ immutable: true, targetName: "OlsService" })
    public static getOls(itemIds: Array<number>, itemTypeId: number, perm: string): Promise<Ols[]> {
        itemIds = S25Util.array.forceArray(itemIds);
        var itemIdPlusDelim = "";
        for (var i = 0; i < itemIds.length; i++) {
            itemIdPlusDelim += "+" + itemIds[i];
        }
        if (itemIdPlusDelim) {
            itemIdPlusDelim = itemIdPlusDelim.substring(1);
        }

        return DataAccess.get(
            DataAccess.injectCaller(
                "/" +
                    OlsService.Type2OlsService[itemTypeId] +
                    "?" +
                    S25Const.typeId2ItemIdB[itemTypeId] +
                    "=" +
                    itemIdPlusDelim +
                    "&perm=" +
                    perm,
                "OlsService.getOls",
            ),
        ).then(function (data: OlsResponse) {
            return S25Util.array.forceArray(data?.object_level_permission?.object_permission);
        });
    }
    public static getOlsMultiple(itemIds: Array<number>, itemTypeId: number, permsArray: Array<string>) {
        var promisesArray = [];
        for (var i = 0; i < permsArray.length; i++) {
            promisesArray.push(OlsService.getOls(itemIds, itemTypeId, permsArray[i]));
        }
        return S25Util.all(promisesArray);
    }

    public static getOlsMultipleByObjId(itemIds: Array<number>, itemTypeId: number, permsArray: Array<string>) {
        var ret: any = {};
        return OlsService.getOlsMultiple(itemIds, itemTypeId, permsArray).then(function (olsData) {
            olsData &&
                jSith.forEach(olsData, function (key: any, objects: any) {
                    jSith.forEach(objects, function (key: any, obj: any) {
                        ret[obj.object_id] = ret[obj.object_id] || {};
                        ret[obj.object_id][obj.right_name] = obj.access_level;
                    });
                });
            return ret;
        });
    }

    public static getOlsMultipleAtLeast(
        itemIds: Array<number>,
        itemTypeId: number,
        permsArray: Array<string>,
        possiblePerms: Array<string>,
        atLeastLevels: Array<string>,
    ) {
        return OlsService.getOlsMultipleByObjId(itemIds, itemTypeId, permsArray).then(function (olsData) {
            var ret: any = [];
            jSith.forEach(itemIds, function (key: any, itemId: any) {
                var hasAtLeastLevel = false;
                jSith.forEach(possiblePerms, function (key: any, right: any) {
                    jSith.forEach(atLeastLevels, function (key: any, level: any) {
                        if (!hasAtLeastLevel) {
                            hasAtLeastLevel =
                                olsData[itemId][(right.perm || right || "").toUpperCase()] ===
                                (right.level || level || "").toUpperCase();
                        }
                    });
                });
                ret.push({ item: itemId, hasAtLeastLevel: hasAtLeastLevel });
            });
            return ret;
        });
    }

    // OlsService.getOlsMultipleAllAtLeast(itemIdArr, 1, ["edit","own"], ["edit", {perm: "own", level: "F"}], levels)
    public static getOlsMultipleAllAtLeast(
        itemIds: Array<number>,
        itemTypeId: number,
        permsArray: Array<string>,
        possiblePerms: Array<string>,
        atLeastLevel: string,
    ) {
        let atLeastLevels = S25Util.array.forceArray(atLeastLevel);
        return OlsService.getOlsMultipleAtLeast(itemIds, itemTypeId, permsArray, possiblePerms, atLeastLevels).then(
            function (itemPermsArray) {
                var allHaveAtLeast = true;
                jSith.forEach(itemPermsArray, function (key: any, obj: any) {
                    allHaveAtLeast = allHaveAtLeast && obj.hasAtLeastLevel;
                });
                return allHaveAtLeast;
            },
        );
    }

    public static getOlsEdit(itemIds: Array<number>, itemTypeId: number, permsArray: Array<string>) {
        let promises: any[] = [];
        permsArray.forEach((perm) => {
            promises.push(
                DataAccess.get(
                    DataAccess.injectCaller(
                        "/" +
                            OlsService.Type2OlsService[itemTypeId] +
                            "?" +
                            S25Const.typeId2ItemIdB[itemTypeId] +
                            "=" +
                            itemIds.join("+") +
                            "&mode=edit&perm=" +
                            perm,
                        "OlsDao.getOls",
                    ),
                ),
            );
        });

        return S25Util.all(promises).then((resp) => {
            return resp;
        });
    }

    public static copyOls(itemTypeId: number, srcId: number, dstId: number) {
        const rightNames = [4, 6].indexOf(itemTypeId) > -1 ? ["edit"] : ["edit"];

        return OlsService.getOlsEdit([srcId], itemTypeId, rightNames).then((resp) => {
            S25Util.replaceDeep(resp, { status: "mod", object_id: dstId, permission_id: "" });
            let promise = jSith.when();
            let promises: any[] = [];
            resp.forEach((payload: any) => {
                promise = promise.then(() => {
                    let perm = payload.object_level_security.object_security[0].right_name;
                    let url = DataAccess.injectCaller(
                        "/" +
                            OlsService.Type2OlsService[itemTypeId] +
                            "?" +
                            S25Const.typeId2ItemIdB[itemTypeId] +
                            "=" +
                            dstId +
                            "&perm=" +
                            perm,
                        "OlsService.copyOls",
                    );
                    return DataAccess.put(url, payload).then((resp) => {
                        return resp;
                    });
                });
                promises.push(promise);
            });

            return S25Util.all(promises).then((done) => {
                return done;
            });
        });
    }

    public static copyCabinetFolderOls(itemTypeId: number, srcId: number, dstId: number) {
        let rightNames = ["EDIT", "CREATE_FLD", "FLD_EDIT", "FLD_CREATE_FLD", "FLD_CREATE_EV", "CREATE_EV", "EV_EDIT"];
        return OlsService.getOlsEdit([srcId], itemTypeId, rightNames).then((resp) => {
            S25Util.replaceDeep(resp, {
                status: "new",
                owner_id: "object25",
                owner_name: "Object Owner",
                object_id: dstId,
            });
            let promise = jSith.when();
            let promises: any[] = [];
            resp.forEach((payload: any) => {
                promise = promise.then(() => {
                    let perm = payload.object_level_security.object_security[0].right_name;
                    let url = DataAccess.injectCaller(
                        "/" +
                            OlsService.Type2OlsService[itemTypeId] +
                            "?" +
                            S25Const.typeId2ItemIdB[itemTypeId] +
                            "=" +
                            dstId +
                            "&perm=" +
                            perm,
                        "OlsService.copyCabinetFolderOls",
                    );
                    return DataAccess.put(url, payload).then((resp) => {
                        return resp;
                    });
                });
                promises.push(promise);
            });

            return S25Util.all(promises).then((done) => {
                return done;
            });
        });
    }
}
